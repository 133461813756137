import React from "react";
import { useHref } from "react-router-dom";
import "./headerStyle.css";

const Header = () => {
  const path = useHref();
  if (path === "/") {
    return (
      <header>
        <div className="container">
          <div className="header-content text-center flex flex-column">
            <h1 className="text-uppercase header-title">
              EL - PRO
            </h1>
            <div  className="text-lead">
            <p>
              חברה ליצירת פתרונות גילוח ושיער
            </p>
            <p>
           אנחנו כאן כדי לתת לכם טיפוח בדרך המהירה והנכונה
            </p>
            <h4 className="title-pop">
            רק לזמן מוגבל! הזמן עכשיו 
            </h4>
            <h4 className="title-pop">
            ותיהנה ממשלוח חינם
            </h4>
            </div>
          </div>
        </div>
      </header>
    );
  }
};

export default Header;
