import React from "react";
import "./footerStyle.css";
import SocialNetwork from "../common/social/SocialNetwork";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footerContainer">
      <div className="title">
        <p> צרו איתנו קשר / Contact us</p>
      </div>
      <SocialNetwork
        WhatsApp={"https://wa.me/9720508142140"}
        Facebook={"https://www.facebook.com/profile.php?id=61561790361841"}
        Phone={"tel:+9720508142140"}
        Message={
          "sms:+9520508142140?&body=היי, אני מעוניין במכונת הגילוח שלכם, אשמח שתחזרו אלי"
        }
        weze={"https://waze.com/ul?ll=31.665434,34.563024&navigate=yes"}
      />
      <div className={"policyContainer"}>
        {/* <Link to={"/termsConditions"} aria-label="Read more about Seminole tax hike">
        <p className={"privacy"}>terms & conditions</p>
        </Link> */}
        <h6 className={"privacy"}>כתובת: הגבורה 1 אשקלון</h6>
        <h6 className={"privacy"}>
          שעות פעילות: א-ה 09:00-19:00 שישי וערבי חג: 09:00-13:00
        </h6>

        <Link
          to={"/privacyPolicy"}
          aria-label="Read more about Seminole tax hike"
        >
          <p className={"privacy"}>תקנון ומדיניות האתר</p>
        </Link>
      </div>
    </div>
  );
};

export default Footer;
